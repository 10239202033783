// fetchUserIsFollowing.js
'use strict';
import debounce from 'lodash/debounce';

import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';
import handleFetchError from '../resource/handleFetchError.js';
import { objectifyArrayById } from '../resource/objectifyArrayById.js';
import addUserIsFollowingDebounceIds from '../action/addUserIsFollowingDebounceIds.js';
import resetUserIsFollowingDebounceIds from '../action/resetUserIsFollowingDebounceIds.js';

import {
  ADD_USERS,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

const API_DEBOUNCE_SEC = 1; // TODO: remote config
const LOCAL_CACHED_SECONDS = 60; // TODO: remote config
const fetchedTimestampObject = {};
let debounceFunction = undefined;

/**
 * Fetch user id is following
 * @kind action
 * @param {string} { userId } - user id for query isFollowing.
 * @return {Promise} Action promise.
 */
export const fetchUserIsFollowing =
  ({ userId } = {}) =>
  async dispatch => {
    if (!userId) return dispatch({ type: '' });

    dispatch(addUserIsFollowingDebounceIds({ userIds: [userId] }));
    if (!debounceFunction) {
      debounceFunction = debounce(
        () => dispatch(fetchUserIsFollowingCore()),
        API_DEBOUNCE_SEC * 1200
      );
    }
    return debounceFunction();
  };

export const fetchUserIsFollowingCore = () => async (dispatch, getState) => {
  const token = getMeData(getState(), 'token');
  const debouncedIds =
    getOperationData(getState(), ['debouncedUserIsFollowing'], 'userIds') || [];
  const canFetchIds = debouncedIds.filter(id => {
    const fetchedTimestamp = fetchedTimestampObject[id] || 0;
    return Date.now() - fetchedTimestamp >= LOCAL_CACHED_SECONDS * 1000;
  });
  if (!token || !canFetchIds.length) {
    return dispatch({ type: '' });
  }

  const fetchOptions = {
    headers: {
      ...getHeaders(),
      Authorization: `Bearer ${token}`,
    },
  };

  const selectPath = ['batchIsFollowing', canFetchIds.join(':')];
  const url = getResourceUrl({ endpoint: '/me/following' });
  canFetchIds.forEach(userId => {
    url.searchParams.append('id', userId);
  });

  dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

  try {
    let response = await fetch(url.href, fetchOptions);

    if (!response.ok) {
      response = await handleFetchError({
        response,
        dispatch,
        getState,
        fetchOptions,
        fetchUrl: url,
      });
    }

    const payload = (await response.json()) || [];
    payload.forEach((user = {}) => {
      const { id } = user;
      fetchedTimestampObject[id] = Date.now();
    });
    const users = objectifyArrayById({
      array: [
        ...canFetchIds.map(id => ({
          id,
          isFollowing: false,
        })),
        ...payload.map(m => ({
          ...m,
          isFollowing: true,
        })),
      ],
    });
    dispatch({
      type: ADD_USERS,
      payload: { users },
    });

    dispatch(resetUserIsFollowingDebounceIds());
    return dispatch({ type: SET_NETWORKING_SUCCESS, payload: { selectPath } });
  } catch (error) {
    return dispatch({
      type: SET_NETWORKING_ERROR,
      payload: { selectPath, error },
    });
  }
};

export default fetchUserIsFollowing;
