// addUserIsFollowingDebounceIds.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import getOperationData from '../selector/getOperationData.js';

/**
 * Add user is following debounce ids
 * @kind action
 * @param {[string]} {userIds} - api debounced query ids.
 * @return {Promise} Action promise.
 */
const addUserIsFollowingDebounceIds =
  ({ userIds }) =>
  async (dispatch, getState) => {
    const currentUserIds =
      getOperationData(getState(), ['debouncedUserIsFollowing'], 'userIds') ||
      [];

    const newUserIds = [...new Set([...currentUserIds, ...userIds])];
    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['debouncedUserIsFollowing'],
        data: { userIds: newUserIds },
      },
    });
  };

export default addUserIsFollowingDebounceIds;
