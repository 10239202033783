// sendUnfollowUser.js
'use strict';
import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import handleFetchError from '../resource/handleFetchError.js';
import getMeData from '../selector/getMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { getReturnType } from '../action/fetchFeeds.js';
import {
  MERGE_USER,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  CLEAR_NETWORKING_NODES,
} from '../ActionTypes.js';
import { POST_FEED } from '../RemoteConfigKeys.js';

/**
 * Send unfollow user
 * @kind action
 * @param {string} { id } - user id.
 * @return {Promise} Action promise.
 */
const sendUnfollowUser =
  ({ id }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');

    if (!token) {
      return;
    }

    const selectPath = ['users', 'unfollow', id];
    const url = getResourceUrl({ endpoint: `/users/${id}/follow` });

    const fetchOptions = {
      method: 'DELETE',
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${token}`,
      },
    };

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      dispatch({
        type: MERGE_USER,
        payload: { user: { id, isFollowing: false } },
      });
      // Fetch feed has 1 minute cache, clear networking after follow/unfollow can fix user not fetch latest feed bug.
      const feedName = getRemoteConfigData(getState(), POST_FEED);
      dispatch({
        type: CLEAR_NETWORKING_NODES,
        payload: {
          selectPaths: [
            [
              'home',
              getReturnType({
                feedName,
              }),
              feedName,
            ],
          ],
        },
      });
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default sendUnfollowUser;
