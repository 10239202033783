// FollowButton.js
'use strict';
import { connect } from 'react-redux';

import FollowButton from '../component/FollowButton.jsx';

import getMeData from '../selector/getMeData.js';
import getUserData from '../selector/getUserData.js';

import sendFollowUser from '../action/sendFollowUser.js';
import sendUnfollowUser from '../action/sendUnfollowUser.js';
import fetchUserIsFollowing from '../action/fetchUserIsFollowing.js';

const mapStateToProps = (state, { userId }) => {
  return {
    meId: getMeData(state, 'id'),
    isFollowing: getUserData(state, userId, 'isFollowing'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendFollowUser: ({ userId }) => dispatch(sendFollowUser({ id: userId })),
    sendUnfollowUser: ({ userId }) =>
      dispatch(sendUnfollowUser({ id: userId })),
    fetchUserIsFollowing: ({ userId }) =>
      dispatch(fetchUserIsFollowing({ userId })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowButton);
