// FollowButton.jsx
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ButtonId } from '../resource/mixpanel.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

import ResetButtonStyle from '../style/ResetButtonStyle.js';
import media from '../style/media.js';
import { color } from '../style/variables.js';

import BlackAddIconSource from '../../img/ic_add_black.svg';
import WhiteCheckIconSource from '../../img/ic_complete_white_xs.svg';
import GrayCheckIconSource from '../../img/ic_complete_gray_xs.svg';

const defaultObject = {};
const defaultFunction = () => null;

export const Theme = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const FollowButton = ({
  meId = null,
  userId = null,
  theme = Theme.DARK,
  isFollowing = null,
  isBigSize = false,
  shouldKeepDisplay = false,
  shouldShowIcon = false,
  mixpanelTrackingPayload = defaultObject,
  sendFollowUser = defaultFunction,
  sendUnfollowUser = defaultFunction,
  fetchUserIsFollowing = defaultFunction,
}) => {
  const { t } = useTranslation();
  const [shouldShow, setShouldShow] = useState(
    isFollowing === false || !meId || shouldKeepDisplay
  );
  useEffect(() => {
    if (meId && userId && isFollowing == null) {
      fetchUserIsFollowing({ userId });
    }
  }, [meId, userId, isFollowing, fetchUserIsFollowing]);
  useEffect(() => {
    if (isFollowing === false) {
      setShouldShow(true);
    }
  }, [isFollowing]);
  if (!shouldShow) {
    return null;
  }
  return (
    <StyledFollowButton
      theme={theme}
      isFollowing={isFollowing}
      isBigSize={isBigSize}
      data-element_id={
        isFollowing ? ButtonId.All.ButtonUnfollow : ButtonId.All.ButtonFollow
      }
      data-tracking_payload={mixpanelTrackingPayload}
      onClick={() => {
        if (isFollowing) {
          return sendUnfollowUser({ userId });
        }
        return sendFollowUser({ userId });
      }}
    >
      {shouldShowIcon ? (
        <img
          src={
            !isFollowing
              ? BlackAddIconSource
              : Theme.DARK === theme
                ? WhiteCheckIconSource
                : GrayCheckIconSource
          }
        />
      ) : null}
      {t(isFollowing ? 'following' : 'follow', {
        ns: TranslationNamespace.GENERAL,
      })}
    </StyledFollowButton>
  );
};

FollowButton.propTypes = {
  meId: PropTypes.string,
  userId: PropTypes.string,
  theme: PropTypes.string,
  isFollowing: PropTypes.bool,
  isBigSize: PropTypes.bool,
  shouldKeepDisplay: PropTypes.bool,
  shouldShowIcon: PropTypes.bool,
  mixpanelTrackingPayload: PropTypes.object,
  sendFollowUser: PropTypes.func,
  sendUnfollowUser: PropTypes.func,
  fetchUserIsFollowing: PropTypes.func,
};

const UnfollowingStyle = {
  borderColor: color.sky,
  backgroundColor: color.sky,
  color: color.neutral[0],
};

const DarkFollowingStyle = {
  borderColor: color.neutral[100],
  backgroundColor: 'rgba(35, 35, 35, 0.4)',
  color: color.neutral[100],
};

const LightFollowingStyle = {
  borderColor: color.neutral[0],
  backgroundColor: 'rgba(246, 246, 246, 0.2)',
  color: color.neutral[0],
};

const StyledFollowButton = styled.button.attrs(
  ({ isFollowing, isBigSize, theme }) => ({
    style: {
      width: isBigSize ? '100%' : 'auto',
      height: `${isBigSize ? 36 : 24}px`,
      fontWeight: isBigSize ? 600 : 400,
      ...(!isFollowing
        ? UnfollowingStyle
        : Theme.DARK === theme
          ? DarkFollowingStyle
          : LightFollowingStyle),
    },
  })
)`
  ${ResetButtonStyle};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    margin-right: 4px;
    width: ${({ isBigSize }) => (isBigSize ? 20 : 12)}px;
    height: ${({ isBigSize }) => (isBigSize ? 20 : 12)}px;
    object-fit: contain;
  }
  ${({ isBigSize }) => {
    if (isBigSize) {
      return css`
        font-size: 20px;
        ${media.mobile`
          font-size: 18px;
        `};
      `;
    }
    return css`
      font-size: 12px;
    `;
  }}
`;

export default FollowButton;
